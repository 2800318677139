import axios from 'axios';

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuth } from '../auth/auth.hooks';
import { ModelArchitectureInfo } from './architecture.types';


export const useModelArchitectureInfo= (modelId: number): UseQueryResult<ModelArchitectureInfo[]> => {
    const { accessToken, config } = useAuth();

    return useQuery({
      queryKey: ['architecture', modelId],
      queryFn:() =>
        axios
          .get<ModelArchitectureInfo[]>(
            `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/architecture`,
            { params: {
              model_id: modelId,
              }, headers: { Authorization: `Bearer ${accessToken}` } }
          )
          .then((response) => response.data),
          
        staleTime: 60000,
        refetchOnWindowFocus: true,
        enabled: Boolean(accessToken),
      }
    );
};


export const useLinkedItemsInfo= (itemId: number): UseQueryResult<number[]> => {
  const { accessToken, config } = useAuth();

  return useQuery({
    queryKey: ['architecture/links', itemId],
    queryFn:() =>
      axios
        .get<number[]>(
          `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/architecture/links`,
          { params: {
            item_id: itemId,
            }, headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then((response) => response.data),
    
      staleTime: 60000,
      refetchOnWindowFocus: true,
      enabled: Boolean(accessToken),
    }
  );
};
