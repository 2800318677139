import axios from 'axios';

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuth } from '../auth/auth.hooks';
import { ProductInfo } from './product.types';


export const getProductsInfo = (): UseQueryResult<ProductInfo[]> => {
    const { accessToken, config } = useAuth();

    return useQuery({
      queryKey: ['product'],
      queryFn:() =>
        axios
          .get<ProductInfo[]>(
            `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/products`,
            { headers: { Authorization: `Bearer ${accessToken}` } }
          )
          .then((response) => response.data),
      
        staleTime: 900000,
        refetchOnWindowFocus: true,
        enabled: Boolean(accessToken),
      }
    );
};


export const getProductInfo= (productId: number, enabled = true): UseQueryResult<ProductInfo> => {
  const { accessToken, config } = useAuth();

  return useQuery({
    queryKey: ['product', productId, enabled],
    queryFn:() =>
      axios
        .get<ProductInfo>(
          `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/products/${productId}`,
          { params: {
            product_id: productId,
            }, headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then((response) => response.data),
    
      staleTime: 900000,
      refetchOnWindowFocus: true,
      enabled: Boolean(accessToken) && enabled,
    }
  );
};


export function postSelectedProductInfo(productId: number, auth: {accessToken: string, config: Record<string, unknown>}): Promise<any> {
  // NOTE: This function is not called every render, so we can't call hooks here, otherwise React will complain
  // The data from useAuth needs to be passed from parent that is called every render
  return axios.post(`${auth.config.REACT_APP_API_BASE_URL}/${auth.config.REACT_APP_ENVIRONMENT}/products/setSelectProduct`,
      {
        product_id: productId
      },
      {
        params: {
            product_id: productId,
        }, headers: { Authorization: `Bearer ${auth.accessToken}` }
      })
}
