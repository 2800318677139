import { jwtDecode } from "jwt-decode";
import {useAuth} from "./auth.hooks";

interface IRoles {
    roles: string[];
}

export enum RoleAuthResult {
    NoAuthFound = 0,
    NoRoles = 1,
    NoMatchingRole = 2,
    RoleFound = 3,
}

export function AuthenticateRolesLocally(rolesRequired: string[]): RoleAuthResult {
    const auth = useAuth();
    if (auth.accessToken === "") return RoleAuthResult.NoAuthFound;

    const token : IRoles = jwtDecode(auth.accessToken);
    const userRoles = token.roles;

    if(userRoles === undefined) return RoleAuthResult.NoRoles;

    for(let i = 0; i < rolesRequired.length; i += 1) {
        for(let j = 0; j < userRoles.length; j += 1) {
            if(rolesRequired[i] === userRoles[j]) {
                return RoleAuthResult.RoleFound;
            }
        }
    }

    return RoleAuthResult.NoMatchingRole;
}

export function IsAdmin(): boolean {
    return AuthenticateRolesLocally(["theremin_ng_admin"]) === RoleAuthResult.RoleFound;
}
