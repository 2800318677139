import axios from 'axios';

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuth } from '../auth/auth.hooks';
import { ComponentDetails } from './device.types';


export const getDeviceInfo= (id: number, enabled: boolean): UseQueryResult<ComponentDetails> => {
    const { accessToken, config } = useAuth();

    return useQuery({
      queryKey: ['device', id, enabled],
      queryFn:() =>
        axios
          .get<ComponentDetails>(
            `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/architecture/device`,
            { params: {
              device_id: id,
              }, headers: { Authorization: `Bearer ${accessToken}` } }
          )
          .then((response) => response.data),
      
        staleTime: 60000,
        refetchOnWindowFocus: true,
        enabled: Boolean(accessToken) && enabled,
      }
    );
};
