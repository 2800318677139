import React, {ChangeEvent, MutableRefObject} from "react";
import {
    DataGrid,
    GridCallbackDetails, GridCellParams,
    GridColDef, GridColumnVisibilityModel, GridEditMode, GridEventListener,
    GridFilterModel, GridPaginationModel, GridRowHeightParams, GridRowHeightReturnValue, GridRowModesModel, GridRowParams,
    GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarExport, MuiEvent
} from "@mui/x-data-grid";
import {Box, Button, MenuItem, Pagination, Select, SelectChangeEvent, Typography} from "@mui/material";
import {GridApiCommunity} from "@mui/x-data-grid/internals";
import {DataflowInfo} from "../dataflow/dataflow.types";
import {CustomGridToolbarQuickFilter} from "../muiCustomComponents/CustomGridToolbarQuickFilter";

type rowDataTypes =
    DataflowInfo[] |
    undefined;

interface DataGridProps {
    columnConfig: GridColDef[];
    itemInfo: rowDataTypes;
    columnVisibilityModel?: GridColumnVisibilityModel;
    columnVisibilityModelChanged?: (newVisibilityModel: GridColumnVisibilityModel) => void;
    allowSearch?: boolean;
    allowMultiSelect?: boolean;
    paginationModel: GridPaginationModel;
    rowCount: number;
    paginationModelChanged: (model: GridPaginationModel, details: GridCallbackDetails) => void;
    filterModelChanged?: (model: GridFilterModel, details: GridCallbackDetails<"filter">) => void;
    searchButtonClick?: () => void;
    searchEnterKey?: () => void;
    initSearch?: string[] | undefined;
    rowHeight?: ((params: GridRowHeightParams) => GridRowHeightReturnValue) | undefined;
    showAddNewAssetButton?: boolean;
    rowsPerPageOptions?: number[];
    rowPadding?: number,
    onRowModesChange?: (newRowModes: GridRowModesModel) => void;
    rowModes?: GridRowModesModel;
    rowEditStart?: (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => void;
    rowEditStop?: GridEventListener<'rowEditStop'>;
    editMode?: GridEditMode;
    isLoading?: boolean;
    /* processRowUpdate?: (newRow: any) => void; */
    onAddNewAsset?: () => void;
    cellKeyDown?: (params: GridCellParams, event: MuiEvent<React.KeyboardEvent>, details: GridCallbackDetails) => void;
    apiRef?: MutableRefObject<GridApiCommunity> | undefined;
    onPageChanged: (event: ChangeEvent<unknown>, page: number) => void;
    onPageSizeChanged: (event: SelectChangeEvent<number>) => void;
}

export function CustomDataGrid(
    {
        columnConfig,
        itemInfo,
        columnVisibilityModel,
        columnVisibilityModelChanged,
        allowSearch = true,
        allowMultiSelect = true,
        paginationModel,
        rowCount,
        paginationModelChanged,
        filterModelChanged,
        searchButtonClick,
        searchEnterKey,
        initSearch,
        rowHeight,
        showAddNewAssetButton = false,
        rowsPerPageOptions = [10, 15, 20],
        rowPadding = 4,
        onRowModesChange,
        rowModes,
        rowEditStart,
        rowEditStop,
        editMode,
        isLoading,
        onAddNewAsset,
        cellKeyDown,
        apiRef,
        onPageChanged,
        onPageSizeChanged,
    }: DataGridProps): JSX.Element {
    const customToolbar = (): JSX.Element => (
        <Box>
            {allowSearch && (
                <Box sx={{ p: 1, pl: 1.5, pb: 0 }}>
                    <CustomGridToolbarQuickFilter
                        onKeyDown={(e) => {
                            if(e.key === "Enter") {
                                if (searchEnterKey) {
                                    searchEnterKey()
                                }
                            }
                        }}
                        initialSearchVal={initSearch === undefined ? "" : initSearch.join(' ')}
                    />
                    <Button
                        variant="text"
                        disableRipple
                        onClick={() => {
                            if (searchButtonClick) {
                                searchButtonClick()
                            }
                        }}
                    >
                        Search
                    </Button>
                </Box>
            )}
            <Box sx={{
                pl: 0.5
            }}>
                <GridToolbarContainer>
                    <GridToolbarColumnsButton
                    slotProps={{ 
                        button: {disableRipple: true, style: {fontSize: 12,} } 
                    }}/>
                    <GridToolbarExport
                    slotProps={{ 
                        button: {disableRipple: true, style: {fontSize: 12,} } 
                    }}/>
                </GridToolbarContainer>
                {showAddNewAssetButton && (
                    <Box style={{ float: "right" }}>
                        <Button disableRipple onClick={onAddNewAsset}>
                            Add New Asset
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    )

    const customPagination = (): JSX.Element => (
        <Box>
            <Typography style={{
                float: "left",
                fontSize: 14,
                paddingTop: "3px",
                paddingRight: "9px",
            }}>
                Rows per page:
            </Typography>
            <Select
                variant="outlined"
                value={paginationModel.pageSize}
                onChange={onPageSizeChanged}
            >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={20}>20</MenuItem>
            </Select>
            <Pagination
                style={{ float: "right" }}
                page={paginationModel.page + 1}
                count={Math.ceil(rowCount / paginationModel.pageSize)}
                onChange={onPageChanged}
            />
        </Box>
    )

    return(
        <DataGrid
            autoHeight
            apiRef={apiRef}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={columnVisibilityModelChanged}
            sx={{
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: `${rowPadding}px` },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: `${rowPadding * 2}px` },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: `${rowPadding * 3}px` },
                '& .MuiDataGrid-cell': {
                    borderBottom: '1px solid #484848',
                },
                '& .MuiPaginationItem-root': {
                    borderRadius: 2,
                },
                '.MuiDataGrid-columnHeaderTitle': { fontWeight: "normal", fontSize: 16 },
                '.MuiDataGrid-row.Mui-odd': { backgroundColor: "#f8f8f8" },
                '.MuiDataGrid-row.Mui-even': { backgroundColor: "#ffffff" },
                '& .MuiDataGrid-row:hover': { backgroundColor: "#ececec" },

            }}
            style={{
                fontFamily: "Noto Sans",
                fontSize: 16,
                fontWeight: "lighter",
            }}
            columns={columnConfig}
            rows={itemInfo !== undefined ? itemInfo : []}
            rowCount={rowCount}
            disableColumnMenu
            pagination
            paginationMode="server"
            filterMode="server"
            paginationModel={paginationModel}
            pageSizeOptions={rowsPerPageOptions}
            slots={{
                toolbar: customToolbar,
                pagination: customPagination
            }}
            loading={isLoading}
            rowModesModel={rowModes}
            getRowHeight={rowHeight}
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
            }
            checkboxSelection={allowMultiSelect}
            onPaginationModelChange={paginationModelChanged}
            onFilterModelChange={filterModelChanged}
            onRowModesModelChange={onRowModesChange}
            onRowEditStart={rowEditStart}
            onRowEditStop={rowEditStop}
            editMode={editMode}
            onCellKeyDown={cellKeyDown}
        />
    );
}
