import axios from 'axios';

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuth } from '../auth/auth.hooks';
import { ThreatModelInfo } from './threatmodel.types';


export const getThreatModelsInfo= (productId: number): UseQueryResult<ThreatModelInfo[]> => {
    const { accessToken, config } = useAuth();

    return useQuery({
      queryKey: ['threatmodel', productId],
      queryFn:() =>
        axios
          .get<ThreatModelInfo[]>(
            `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/threatmodels`,
            { params: {
              product_id: productId,
              }, headers: { Authorization: `Bearer ${accessToken}` } }
          )
          .then((response) => response.data),
      
        staleTime: 60000,
        refetchOnWindowFocus: true,
        enabled: Boolean(accessToken),
      }
    );
};


export const getThreatModelInfo= (modelId: number, enabled: boolean): UseQueryResult<ThreatModelInfo> => {
  const { accessToken, config } = useAuth();

  return useQuery({
    queryKey: ['threatmodel', modelId, enabled],
    queryFn:() =>
      axios
        .get<ThreatModelInfo>(
          `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/threatmodels/model`,
          { params: {
            model_id: modelId,
            }, headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then((response) => response.data),
    
      staleTime: 60000,
      refetchOnWindowFocus: true,
      enabled: Boolean(accessToken) && enabled,
    }
  );
};
