import axios from 'axios';

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuth } from '../auth/auth.hooks';
import {DataflowInfo} from './dataflow.types';
import { DropDownItem } from '../detailsDialog/detailsDialog.types';


export const useDataflowInfos = (modelId: number, limit: number, page: number, search: string[] | undefined): UseQueryResult<DataflowInfo[]> => {
    const { accessToken, config } = useAuth();

    return useQuery({
      queryKey: ['dataflow', { 'modeId': modelId, 'limit': limit, 'page': page, 'search': search }],
      queryFn:() =>
        axios
          .get<DataflowInfo[]>(
            `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/dataflow/all`,
            { params: {
              modelId,
              limit,
                page,
                ...(search !== undefined ? { search: String(search) } : {}),
            },headers: { Authorization: `Bearer ${accessToken}` } }
          )
          .then((response) => response.data),
      
        staleTime: 60000,
        refetchOnWindowFocus: true,
        enabled: Boolean(accessToken),
      }
    );
  };

export const useDataflowInfoCount = (modelId: number, search: string[] | undefined): UseQueryResult<number> => {
    const { accessToken, config } = useAuth();

    return useQuery({
      queryKey: ['dataflow_rows', { 'modeId': modelId, 'search': search}],
      queryFn:() =>
            axios
                .get<number>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/dataflow/rows`,
                    { params: {
                      modelId,
                      ...(search !== undefined ? { search: String(search) } : {}),
                    },headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
}

export const useDataflowInfo = (dataflowId: number, enabled = true): UseQueryResult<DataflowInfo> => {
  const { accessToken, config } = useAuth();

  return useQuery({
    queryKey: ['dataflow', { 'dataflowId': dataflowId, enabled }],
    queryFn:() =>
      axios
        .get<DataflowInfo>(
          `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/dataflow`,
          { params: {
            dataflowId,
          },headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then((response) => response.data),
    
      staleTime: 60000,
      refetchOnWindowFocus: true,
      enabled: Boolean(accessToken) && enabled,
    }
  );
};




export const getSourceDropdown= (modelID: number): UseQueryResult<DropDownItem[]> => {
  const { accessToken, config } = useAuth();

  return useQuery({
    queryKey: ['source', modelID],
    queryFn:() =>
      axios
        .get<DropDownItem[]>(
          `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/architecture/source/dd`,
          { params: {
            model_id: modelID,
            }, headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then((response) => response.data),
    
      staleTime: 60000,
      refetchOnWindowFocus: true,
      enabled: Boolean(accessToken),
    }
  );
};
